var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row manual-detail manual-page-layout" }, [
    _c(
      "div",
      { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
      [
        _c(
          "c-card",
          {
            staticClass: "cardClassDetailForm",
            attrs: { title: "개정 전", height: "400px" },
          },
          [
            _c(
              "template",
              { slot: "card-select" },
              [
                _c("c-select", {
                  attrs: {
                    comboItems: _vm.gridrev.data,
                    type: "custom",
                    typetext: "개정이력",
                    itemText: "revisionNum",
                    itemValue: "opmGuidebookId",
                    name: "selectedopmGuidebookId",
                    label: "",
                  },
                  on: { input: _vm.rowClickRev },
                  model: {
                    value: _vm.selectedopmGuidebookId,
                    callback: function ($$v) {
                      _vm.selectedopmGuidebookId = $$v
                    },
                    expression: "selectedopmGuidebookId",
                  },
                }),
              ],
              1
            ),
            _c("template", { slot: "card-detail" }, [
              _c("div", { staticClass: "col-12" }, [
                _c("div", { domProps: { innerHTML: _vm._s(_vm.revRemark) } }),
              ]),
            ]),
          ],
          2
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
      [
        _c(
          "c-card",
          {
            staticClass: "cardClassDetailForm",
            attrs: { title: "개정 후", height: "400px" },
          },
          [
            _c("template", { slot: "card-detail" }, [
              _c("div", { staticClass: "col-12" }, [
                _c("div", {
                  domProps: { innerHTML: _vm._s(_vm.popupParam.remark) },
                }),
              ]),
            ]),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }