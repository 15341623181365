<template>
  <div class="row manual-detail manual-page-layout">
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
      <c-card title="개정 전" class="cardClassDetailForm" height="400px">
        <template slot="card-select">
          <c-select
            :comboItems="gridrev.data"
            type="custom"
            typetext="개정이력"
            itemText="revisionNum"
            itemValue="opmGuidebookId"
            name="selectedopmGuidebookId"
            label=""
            v-model="selectedopmGuidebookId"
            @input="rowClickRev"
          ></c-select>
        </template>
        <template slot="card-detail">
          <div class="col-12">
            <div v-html="revRemark"></div>
          </div>
        </template>
      </c-card>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
      <c-card title="개정 후" class="cardClassDetailForm" height="400px">
        <template slot="card-detail">
          <div class="col-12">
            <div v-html="popupParam.remark"></div>
          </div>
        </template>
      </c-card>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'safety-oper-guide-bood-editor',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        groupId: '',
        remark: '',
        opmGuidebookId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      revRemark: '',
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'MANUAL_FILE',
        taskKey: '',
      },
      title: '',
      detailUrl: '',
      data: {
        sysMenuId: '',  // 메뉴 SEQ
        menuNm: '',  // 메뉴 SEQ
        content: '',  // 매뉴얼 내용
        useFlag: 'Y',  // 사용여부
      },
      selectedopmGuidebookId: null,
      gridrev: {
        columns: [
        ],
        data: [],
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
    $route(){
    }
  },
  methods: {
    init() {
      // url setting
      this.detailUrl = selectConfig.sys.manual.get.url;
      this.revlistUrl = selectConfig.sop.opm.guidebook.rev.url;
      this.detailUrl = selectConfig.sop.opm.guidebook.get.url;
      
      this.getRevList();
    },
    getRevList() {
      this.$http.url = this.revlistUrl;
      this.$http.type = 'GET';
      this.$http.param = {groupId: this.popupParam.groupId};
      this.$http.request((_result) => {
        this.gridrev.data = this.$_.reject(_result.data, {opmGuidebookId: this.popupParam.opmGuidebookId });

        if (this.gridrev.data && this.gridrev.data.length > 0) {
          this.selectedopmGuidebookId = this.gridrev.data[0].opmGuidebookId
          this.getDetail();
        }
      },);
    },
    getDetail() {
      this.$http.url = this.$format(this.detailUrl, this.selectedopmGuidebookId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.revRemark = _result.data.remark;
      },);
    },
    rowClickRev(row) {
      if (row) {
        if (row != this.popupParam.opmGuidebookId) {
          this.selectedopmGuidebookId = row;
          this.getDetail();
        }
      }
    },
  }
};
</script>
<style lang="scss">
.manual-detail {
  padding: 10px;
}
.manual-page-layout img {
  width: 100%;
  height: auto;
}
</style>
